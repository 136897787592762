import React from "react";

export default function ProgressTimer({animationTrigger}) {
	return (
		<div className="progress-timer-container">
			<div className="progress-line-base"></div>
			<div
				className={`progress-line-timer ${
					animationTrigger ? "animation-trigger" : ""
				}`}
			></div>
		</div>
	);
}
