
import Link from 'next/link'
import React, { useContext } from 'react'
import { Navigation, Pagination, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import 'swiper/css/pagination';
import Router, { useRouter } from 'next/router';
import Image from 'next/image';
import UpdatesHeader from './UpdatesHeader';
import { BASE_URL } from '../../utils/FetchData';
import { AnimatePresence, motion } from 'framer-motion';
import { nextRouteContext } from '../../contexts/nextRouteContext';
import { UpdateMobileCard } from './UpdateMobileCard';
import { useState } from 'react';
const UpdatesMobile = ({ News_Heighlights }) => {
    const { locale } = useRouter()
    const { setNextRoute } = useContext(nextRouteContext)
    const [slideNumber, setSlideNumber] = useState('0' + 1)
    return (
        <div className='updates-section-mobile'>
            <UpdatesHeader headline={News_Heighlights?.Headline} title={News_Heighlights?.Title} btnText={News_Heighlights?.Button_Text} />
            <Swiper
                key={'updates'}
                style={{ direction: locale == "ar" ? "rtl" : "ltr" }}
                className="swiper-update-section"
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 40,
                    },
                    550: {
                        slidesPerView: 1.5,
                        spaceBetween: 40,
                    },
                    768: {
                        slidesPerView: 2.25,
                        spaceBetween: 40,
                    },
                }}
                modules={[Navigation, Pagination]}
                navigation={{
                    nextEl: ".swiper-button-next-updates",
                    prevEl: ".swiper-button-prev-updates",
                }}
                pagination={{
                    type: "progressbar",
                    el: ".swiper-updates-navigation",
                }}
                onSlideChange={(swiper) => {
                    setSlideNumber('0' + (swiper.realIndex + 1))
                }}
            // loop={true}
            >

                <div className='single-card'>
                    {News_Heighlights?.Articles?.data?.sort((a, b) => (new Date(b.attributes.date) - new Date(a.attributes.date))).map((data, index) => (
                        <SwiperSlide key={index}>
                            <AnimatePresence>
                                <UpdateMobileCard
                                    data={data}
                                    index={index}
                                />
                            </AnimatePresence>
                        </SwiperSlide>
                    ))}
                </div>
            </Swiper>
            <div className='swiper-navigation-continer'>
                <div className='navigation-container'>
                    <div className='slide-number-container bold'>
                        <div>{slideNumber}</div>
                        <div>{`0${News_Heighlights?.Articles?.data?.length}`}
                        </div>
                    </div>
                    <div className='swiper-updates-navigation swiper-pagination-progressbar'></div>
                </div>
                <div className={`arrows-container ${News_Heighlights?.Articles?.data?.length == 1 ? "d-none" : ""}`}
                >
                    <div className=" swiper-button-prev swiper-button-prev-updates  black-arrow-prev updates-arrow"></div>
                    <div className=" swiper-button-next black-arrow-next swiper-button-next-updates updates-arrow"></div>
                </div>
            </div>
        </div>
    )
}

export default UpdatesMobile
