import Image from "next/legacy/image";
import { useContext } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Button from "../common/Button";
import { BASE_URL } from "../../utils/FetchData";
import { nextRouteContext } from '../../contexts/nextRouteContext';
import FadeIn from "../Animations/FadeIn";
const OurPortfolioSingleCards = ({
    data,
    index,
    businessProjectsLength,
    projectsLength,
    isHover,
    setIsHover,
    delay
}) => {
    const { setNextRoute } = useContext(nextRouteContext)
    return (
        <motion.div
            initial={{ opacity: "0", }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: parseFloat(((index + delay) / 8) + 0.3), duration: .5 }}
            className={`img-container`}
            key={index + "tab-data"}
            onHoverStart={() => {
                setIsHover(index + projectsLength + businessProjectsLength);
            }}
            onHoverEnd={() => {
                setIsHover(0);
            }}
        >
            <div className="overlay"></div>
            <Image
                src={
                    BASE_URL +
                    data?.attributes?.Home_Card?.Img_Desktop?.data?.attributes?.url
                }
                alt={
                    data?.attributes?.Home_Card?.Img_Desktop?.data?.attributes?.name
                }
                layout="fill"
                objectFit="cover"
                objectPosition={"top"}
            />
            <AnimatePresence>
                {/* {isHover == index+businessProjectsLength+projectsLength && ( */}
                <div className="slide-data">
                    <div className="logos">
                        <div className="main-logo">
                            <Image
                                src={
                                    data?.attributes?.Home_Card?.Logo?.data?.attributes?.url ? BASE_URL +
                                        data?.attributes?.Home_Card?.Logo?.data?.attributes?.url :
                                        "/icons/project-logo.svg"
                                }
                                alt={
                                    data?.attributes?.Home_Card?.Logo?.data?.attributes?.name ?
                                        data?.attributes?.Home_Card?.Logo?.data?.attributes?.name : "logo"
                                }
                                layout="fill"
                                objectFit="contain"
                                objectPosition={"top"}
                            />
                        </div>
                        {data.attributes.Slug == "hyde-park-new-cairo" &&
                            <div className="link-logo">
                                <Image
                                    src={"/icons/360.svg"}
                                    alt={"logo"}
                                    layout="fill"
                                    objectFit="contain"
                                    objectPosition={"top"}
                                />
                            </div>
                        }
                    </div>
                    <FadeIn customClass="data">
                        <span className="bold">
                            {data?.attributes?.Banner?.Headline}
                        </span>
                        <h2 className="bold _eleX">
                            {data?.attributes?.Project_Name}
                        </h2>
                        <p className="light _eleX">
                            {data?.attributes?.Home_Card?.description}
                        </p>
                        <div className="buttons-group bold _eleX">
                            <div>
                                <Button
                                    text={
                                        data?.attributes?.Home_Card?.Button_Text1
                                    }
                                    link={`/projects/${data?.attributes?.Home_Card?.Button_Slug2}/booking`}
                                />
                            </div>
                            <div onClick={() => { setNextRoute(data?.attributes?.Project_Name) }}>
                                <Button

                                    text={data?.attributes?.Home_Card?.Button_Text2}
                                    link={`/projects/${data?.attributes?.Home_Card?.Button_Slug2}`}
                                />
                            </div>
                        </div>
                    </FadeIn>
                </div>
                {/* )}  */}
            </AnimatePresence>
        </motion.div>
    )
}

export default OurPortfolioSingleCards
