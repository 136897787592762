import { Tabs, TabPanel, TabList, Tab } from "react-tabs";
import { Navigation, Pagination, EffectFade, Mousewheel } from "swiper";
import Image from "next/legacy/image";
import { useContext } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Button from "../common/Button";
import { BASE_URL } from "../../utils/FetchData";
import { nextRouteContext } from '../../contexts/nextRouteContext';
import FadeIn from "../Animations/FadeIn";

const OurPortfolioCards = ({ data, index, businessProjectsLength, projectsLength, isHover }) => {
    const { setNextRoute } = useContext(nextRouteContext)
    const container = {
        visible: {
            transition: {
                staggerChildren: 0.1,
                delayChildren: 0,
                duration: .1
            }
        }
    }
    const childs = {
        hidden: { opacity: 0, y: 80 },
        visible: { opacity: 1, y: 0 }
    }
    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: "0", }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ delay: .2, duration: .75 }}
                className="img-container"
            >
                <div className="overlay"></div>
                <Image
                    src={
                        BASE_URL +
                        data?.attributes?.Home_Card?.Img_Desktop?.data?.attributes?.url
                    }
                    alt={
                        data?.attributes?.Home_Card?.Img_Desktop?.data?.attributes?.name
                    }
                    layout="fill"
                    objectFit="cover"
                />
                <AnimatePresence>
                    {isHover == index +
                        projectsLength +
                        businessProjectsLength
                        && (
                            <div className="slide-data">
                                <div className="logos">
                                    <div className="main-logo">
                                        <Image
                                            src={
                                                data?.attributes?.Home_Card?.Logo?.data?.attributes?.url ? BASE_URL +
                                                    data?.attributes?.Home_Card?.Logo?.data?.attributes?.url :
                                                    "/icons/project-logo.svg"
                                            }
                                            alt={
                                                data?.attributes?.Home_Card?.Logo?.data?.attributes?.name ?
                                                    data?.attributes?.Home_Card?.Logo?.data?.attributes?.name : "logo"
                                            }
                                            layout="fill"
                                            objectFit="contain"
                                            objectPosition={"top"}
                                        />
                                    </div>
                                    {data.attributes.Slug == "hyde-park-new-cairo" &&
                                        <div className="link-logo">
                                            <Image
                                                src={"/icons/360.svg"}
                                                alt={"logo"}
                                                layout="fill"
                                                objectFit="contain"
                                                objectPosition={"top"}
                                            />
                                        </div>
                                    }
                                </div>
                                <FadeIn customClass="data">
                                    <span className="bold _eleX">
                                        {data?.attributes?.Banner?.Headline}
                                    </span>
                                    <h2 className="bold _eleX">
                                        {data?.attributes?.Project_Name}
                                    </h2>
                                    <p className="light _eleX">
                                        {data?.attributes?.Home_Card?.description}
                                    </p>
                                    <div className="buttons-group _eleX">
                                        <div>
                                            <Button
                                                text={
                                                    data?.attributes?.Home_Card?.Button_Text1
                                                }
                                                link={`/projects/${data?.attributes?.Home_Card?.Button_Slug2}/booking`}
                                            />
                                        </div>
                                        <div onClick={() => { setNextRoute(data?.attributes?.Project_Name) }}>
                                            <Button

                                                text={
                                                    data?.attributes?.Home_Card?.Button_Text2
                                                }
                                                link={`/projects/${data?.attributes?.Home_Card?.Button_Slug2}`}
                                            />
                                        </div>
                                    </div>
                                </FadeIn>
                            </div>
                        )}
                </AnimatePresence>
            </motion.div>
        </AnimatePresence>
    )
}

export default OurPortfolioCards