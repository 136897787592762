import { Tabs, TabPanel, TabList, Tab } from "react-tabs";
import { Navigation, Pagination, EffectFade, Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { useRouter } from "next/router";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import OurPortfolioCards from "./OurPortfolioCards";
import OurPortfolioSingleCards from "./OurPortfolioSingleCards";
import Magnitizer from "../Animations/Magnitizer";
import ArrowsSpliting from "../Animations/ArrowsSpliting";
import FadeIn from "../Animations/FadeIn";
const OurPortfolio = ({ Our_Portfolio }) => {
	const { locale } = useRouter();
	const [slideNumber, setSlideNumber] = useState("0" + 1);
	const [activeIndex, setActiveIndex] = useState(0);
	const [isHover, setIsHover] = useState(0);
	return (
		<div className="portfolio-container">
			{/* to add background image to component */}
			<div className="white"></div>
			<div className="bg"></div>
			<div className="white1"></div>
			{/* to add background image to component */}
			<Tabs>
				<div className="tabs-container">
					<FadeIn customClass="tabs-header">
							<h2 className="light _eleX">{Our_Portfolio?.Header}</h2>
						<TabList>
							{Our_Portfolio?.Project_Tabs?.data.map((tab, index) => (
								<Tab key={index}>
										<div key={index} className="_eleX">
											<span>
												<ArrowsSpliting xValue="15%">
													{tab?.attributes?.name}
												</ArrowsSpliting>
											</span>
										</div>
								</Tab>
							))}
						</TabList>
					</FadeIn>
					<div className="tabs-panels">
						{Our_Portfolio?.Project_Tabs?.data?.map(
							(tab, index) =>
								tab?.attributes?.projects?.data?.length +
									tab?.attributes?.business_projects?.data?.length +
									tab?.attributes?.commercial_projects?.data?.length
									> 2 ? (
									<TabPanel key={index + "tabs"}>
										<div className={`portfolio-swiper`}>
											<Swiper
												key={
													tab?.attributes?.projects?.data?.length +
													tab?.attributes?.business_projects?.data?.length
												}
												style={{ direction: locale == "ar" ? "rtl" : "ltr" }}
												modules={[
													Navigation,
													Pagination,
													EffectFade,
													Mousewheel,
												]}
												
												navigation={{
													nextEl: ".swiper-button-next-port",
													prevEl: ".swiper-button-prev-port",
												}}
												className="swiper-portfolio-section"
												slidesPerView={2.25}
												spaceBetween={20}
												centeredSlides={true}
												roundLengths={true}
												loop={true}
												onSlideChange={(swiper) => {
													setSlideNumber("0" + (swiper.realIndex + 1));
													setActiveIndex(swiper.realIndex);
													setIsHover(swiper.realIndex);
												}}
												pagination={{
													type: "progressbar",
													el: ".swiper-portfolio-navigation",
													clickable: true,
													renderBullet: (index, className) => {
														return (
															'<span class="' + className + '">' + "</span>"
														);
													},
												}}
											>
												{/* normalProjects */}
												{tab?.attributes?.projects?.data?.map(
													(data, index) => (
														<SwiperSlide key={index + "panel"}>
															<OurPortfolioCards
																index={index}
																data={data}
																isHover={isHover}
																projectsLength={0}
																businessProjectsLength={0}
															/>
														</SwiperSlide>
													)
												)}
												{/* businessProjects */}
												{tab?.attributes?.business_projects?.data?.map(
													(data, businessIndex) => (
														<SwiperSlide key={businessIndex + "panelBusniess"}>
															<OurPortfolioCards
																index={businessIndex}
																data={data}
																isHover={isHover}
																projectsLength={tab?.attributes?.projects?.data.length}
																businessProjectsLength={0}
															/>
														</SwiperSlide>
													)
												)}
												{/* commercialProjects */}
												{tab?.attributes?.commercial_projects?.data?.map(
													(data, commercialIndex) => (
														<SwiperSlide key={commercialIndex + "panelCommercial"}>
															<OurPortfolioCards
																index={commercialIndex}
																data={data}
																isHover={isHover}
																projectsLength={tab?.attributes?.projects?.data.length}
																businessProjectsLength={tab?.attributes?.business_projects?.data.length}
															/>
														</SwiperSlide>
													)
												)}
											</Swiper>
											<AnimatePresence>
												{
													<motion.div className="swiper-navigation-continer"
														initial={{ opacity: 0 }}
														whileInView={{ opacity: 1 }}
														viewport={{ once: true }}
														transition={{ duration: 0.2, delay: 0.5 }}
													>
														<div className="navigation-container">
															<div className="slide-number-container bold">
																<div>{slideNumber}</div>
																<div>
																	{`0${tab?.attributes?.projects?.data?.length +
																		tab?.attributes?.business_projects?.data?.length +
																		tab?.attributes?.commercial_projects?.data?.length

																		}`}
																</div>
															</div>
															<div className="swiper-portfolio-navigation swiper-pagination-progressbar"></div>
														</div>
														<div className="arrows-container">
															<Magnitizer dataDest={1.5}>
																<div className="swiper-button-prev swiper-button-prev-port  portfolio-arrow"></div>
															</Magnitizer>
															<Magnitizer dataDest={1.5}>
																<div className="swiper-button-next swiper-button-next-port portfolio-arrow"></div>
															</Magnitizer>
														</div>
													</motion.div>
												}
											</AnimatePresence>
										</div>
									</TabPanel>
								) : (
									<TabPanel key={index + "tabsl2"}>
										<div className={`two-img-container
										${tab?.attributes?.projects?.data?.length + tab?.attributes?.business_projects?.data?.length == 1 +
											tab?.attributes?.commercial_projects?.data?.length == 1 && "one-img"}
										`}>
											{/* normalProjects */}
											<AnimatePresence>
												{tab?.attributes?.projects?.data?.map(
													(data, index) => (
														<OurPortfolioSingleCards
															key={index}
															index={index}
															data={data}
															isHover={isHover}
															setIsHover={setIsHover}
															projectsLength={0}
															businessProjectsLength={0}
															delay={0}
														/>
													)
												)}
											</AnimatePresence>
											{/* businessProjects */}
											<AnimatePresence>
												{tab?.attributes?.business_projects?.data?.map(
													(data, businessIndex) => (
														<OurPortfolioSingleCards
															key={businessIndex}
															index={businessIndex}
															data={data}
															isHover={isHover}
															setIsHover={setIsHover}
															projectsLength={tab?.attributes?.projects?.data?.length}
															businessProjectsLength={0}
															delay={businessIndex + tab?.attributes?.projects?.data?.length}
														/>
													)
												)}
											</AnimatePresence>
											{/* commercialProjects */}
											<AnimatePresence>
												{tab?.attributes?.commercial_projects?.data?.map(
													(data, commercialIndex) => (
														<OurPortfolioSingleCards
															key={commercialIndex}
															index={commercialIndex}
															data={data}
															isHover={isHover}
															setIsHover={setIsHover}
															delay={commercialIndex + (tab?.attributes?.projects?.data?.length) + (tab?.attributes?.business_projects?.data?.length)}
															projectsLength={tab?.attributes?.projects?.data?.length}
															businessProjectsLength={tab?.attributes?.business_projects?.data?.length}
														/>
													)
												)}
											</AnimatePresence>
										</div>
									</TabPanel>
								)
						)}
					</div>
				</div >
			</Tabs >
		</div >
	);
};

export default OurPortfolio;
