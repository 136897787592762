
import React, { useEffect, useState } from 'react'
import { Navigation, Pagination, EffectFade, Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import 'swiper/css/pagination';
import UpdatesHeader from './UpdatesHeader'
import { useRouter } from 'next/router';
import UpdateCard from './UpdateCard';
import { AnimatePresence, motion } from "framer-motion"
import Magnitizer from '../Animations/Magnitizer';
const Updates = ({ News_Heighlights }) => {
    const { locale } = useRouter()
    const [slideNumber, setSlideNumber] = useState('0' + 1)
    const [isHover, setIsHover] = useState(-1)
    const [activeIndex, setActiveIndex] = useState(-1)
    return (
        <div className='updates-section'>
            <UpdatesHeader headline={News_Heighlights?.Headline} title={News_Heighlights?.Title} btnText={News_Heighlights?.Button_Text} />
            <div className='updates-swiper-section'>
                  <div className='swiper-navigation-section'>
                    <AnimatePresence>
                        <motion.span
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ delay: 0.1, duration: .5 }}
                            viewport={{ once: true }}
                            key={News_Heighlights?.Headline2}
                        >{News_Heighlights?.Headline2}</motion.span>
                    </AnimatePresence>
                    <AnimatePresence>
                        <motion.div className='navigation-container'
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: News_Heighlights?.Articles?.data?.length > 2?1:0 }}
                            transition={{ delay: 0.5, duration: .5 }}
                            viewport={{ once: true }}
                            key={News_Heighlights?.Articles?.data?.length + 'length'}
                        >
                            <div className='slide-number-container bold'>
                                <div>{slideNumber}</div>
                                <div>{'0' + (Math.ceil(News_Heighlights?.Articles?.data?.length / 2.5) + 2)}</div>
                            </div>
                            <progress id="file" value={slideNumber} max={News_Heighlights?.Articles?.data?.length - 1}></progress>

                            {/* <div className='swiper-update-navigation navigation-bar swiper-pagination-progressbar'></div> */}
                        </motion.div>
                        <motion.div className='arrows-container'
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: News_Heighlights?.Articles?.data?.length > 2?1:0 }}
                            transition={{ delay: 0.1, duration: .5 }}
                            viewport={{ once: true }}
                        >
                            <Magnitizer dataDest={1.5}>
                                <div className=" swiper-button-prev swiper-button-prev-updates  black-arrow-prev updates-arrow"></div>
                            </Magnitizer>
                            <Magnitizer dataDest={1.5}>
                                <div className=" swiper-button-next black-arrow-next swiper-button-next-updates updates-arrow"></div>
                            </Magnitizer>
                        </motion.div>
                    </AnimatePresence>
                </div>
                <Swiper
                    key={'updates' + News_Heighlights?.Articles?.data?.length}
                    style={{ direction: locale == "ar" ? "rtl" : "ltr" }}
                    modules={[Navigation, Pagination, EffectFade, Mousewheel]}
                    navigation={{
                        nextEl: ".swiper-button-next-updates",
                        prevEl: ".swiper-button-prev-updates",
                    }}
                    // pagination={{
                    //     type: "progressbar",
                    //     el: ".swiper-update-navigation",
                    //     clickable: true,
                    //     renderBullet: (index, className) => {
                    //         return '<span class="' + className + '">' + "</span>"
                    //     },
                    // }}
                    // loop={true}
                    className="swiper-update-section"
                    slidesPerView={2.5}
                    
                    spaceBetween={0}
                    onSlideChange={(swiper) => {
                        setSlideNumber('0' + (swiper.activeIndex + 1));
                        // setIsHover(swiper.activeIndex);
                        setActiveIndex(swiper.activeIndex)
                    }}

                >
                    {News_Heighlights?.Articles?.data?.sort((a, b) => (new Date(b.attributes.date) - new Date(a.attributes.date))).map((data, index) => (

                        <motion.div className='single-card' key={index}>
                            <SwiperSlide key={index}>
                                <AnimatePresence>
                                    <motion.div
                                        initial={{ opacity: 0, height: '100%' }}
                                        whileInView={{ opacity: 1 }}
                                        transition={{ delay: index < 3 ? parseFloat((index / 5) + 0.2) : .1, duration: .5 }}
                                        viewport={{ once: true, amount: .3 }}
                                    >
                                        <UpdateCard
                                            index={index}
                                            isHover={isHover}
                                            setIsHover={setIsHover}
                                            activeIndex={activeIndex}
                                            data={data}
                                        />
                                    </motion.div>
                                </AnimatePresence>
                            </SwiperSlide>
                        </motion.div>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}

export default Updates
