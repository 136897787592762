import { useInView, inView } from "react-intersection-observer"
import React, { useContext, useEffect } from 'react'
import CountUp from "react-countup"
import { AnimatePresence, motion } from "framer-motion";
import { isMobileContext } from "../../contexts/isMobileContext";
import { useRouter } from "next/router";
const SingleNumber = ({ text, number, index, unit }) => {
    //made ref for each number to solve isVisible issue
    const [ref, inView] = useInView({ threshold: 1, triggerOnce: true })
    const { isMobile } = useContext(isMobileContext)
    const {locale} = useRouter();
    return (
        <AnimatePresence>
            <motion.div
            initial={{ opacity: "0", }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: isMobile ?  0.2 : parseFloat((index / 5) + 0.3), duration: .5 }}
            className='single-number' key={index}>
                <div className='number' ref={ref}>
                    <CountUp
                        start={0}
                        end={inView?number:0}
                        duration={parseFloat(index/5+2)}
                        separator=","
                        prefix={index <= 1 ? "+" : ""}
                        // suffix={index != 2&&unit!=null ? unit : ""}
                        suffix={(index != 2 && unit != null && locale == "ar") ? ` ${unit}` : (index != 2 && unit != null) ? unit : ""}
                        decimals={index<=1?true:false}

                    />
                </div>
                <motion.div className='single-number-text'>{text}</motion.div>
            </motion.div>
        </AnimatePresence>
    )
}

export default SingleNumber
