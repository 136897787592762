
import { useCycle } from 'framer-motion'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { isMobileContext } from '../../contexts/isMobileContext'
import SearchInputs from '../common/SearchInputs'
const SearchSection = ({ projectsTabs, customClass }) => {
    const { isMobile } = useContext(isMobileContext)
    const [isSearchMenuOpened] = useCycle(false, true)

    return (
        <>
            <div className={`search-section-container ${isMobile && 'search-section-container-mobile'} ${customClass && customClass}`}>
                <SearchInputs
                    projectsTabs={projectsTabs}
                    isSearchMenuOpened={isSearchMenuOpened}
                />
            </div>
        </>
    )
}

export default SearchSection