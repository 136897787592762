import React, { Fragment, useContext, useEffect, useLayoutEffect, useState } from "react";
import { Navigation, Pagination, Mousewheel, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Image from "next/legacy/image";
import { useRouter } from "next/router";
import Button from "../common/Button";
import SearchSection from "./SearchSection";
import { isMobileContext } from "../../contexts/isMobileContext";
import { nextRouteContext } from "../../contexts/nextRouteContext";
import { BASE_URL } from "../../utils/FetchData";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "next-i18next";
import ProgressTimer from "../common/ProgressTimer";
import Magnitizer from "../Animations/Magnitizer";
import FadeIn from "../Animations/FadeIn";
import ArrowsSpliting from "../Animations/ArrowsSpliting";
const Banner = ({ bannerData, projectsTabs,pageLoading }) => {
	const { locale } = useRouter();
	const { t } = useTranslation("common");
	const [slideNumber, setSlideNumber] = useState(1);
	const [loading, setLoading] = useState(true);
	const [animationTrigger, setAnimationTrigger] = useState(true);
	const { isMobile } = useContext(isMobileContext);
	const { nextRoute, setNextRoute } = useContext(nextRouteContext);
	const [slides, setSlides] = useState(0)
	const [prevSlide, setprevSlide] = useState(-1)
	const [canClick, setCanClick] = useState(false)
	const [sliderDir, setSliderDir] = useState(true)

	setTimeout(() => {
		if (animationTrigger == false) {
			setAnimationTrigger(true);
		}
	}, 100);

	const nextSlides = () => {
		setCanClick(false)
		if (slides != bannerData?.length - 1) {
			setSlideNumber(slideNumber + 1);
			setAnimationTrigger(false);
			setSlides(slides + 1)
			setprevSlide(prevSlide + 1)

		}
	}
	const prevSlides = () => {
		setCanClick(false)
		if (slides != 0) {
			setSlideNumber(slideNumber - 1);
			setAnimationTrigger(false);
			setSlides(slides - 1)
			setprevSlide(prevSlide - 1)
		}
	}
	const resetSlider = () => {
		setSlides(0)
		setprevSlide(-1)
		setSlideNumber(1)
		setAnimationTrigger(false);
	}
	useEffect(() => {
		if (slides == 0) {
			setSliderDir(true)
		} else if (slides == bannerData?.length - 1) {
			setSliderDir(false)
		}
	}, [slides, bannerData])

	useEffect(() => {
		const timeOut = setTimeout(() => {
			if (sliderDir) {
				nextSlides()
			} else {
				prevSlides()
			}
		}, 10150)
		return () => clearTimeout(timeOut)
	}, [sliderDir, slides])
	// useLayoutEffect(() => {
	// 	document.body.classList.add("no-scroll")
		
	// }, [])
	return (
		<>
			<div className="banner-section-container">
				<div className="banner-wrapper">
					{bannerData?.map((item, index) => (
						<Fragment key={index}>
							<div className={`
							${slides == index ? "active" : ""} 
							${prevSlide == index ? "prev" : ""}
							 banner-img`} key={index}>
								<div className="overlay"></div>
								<Image
									src={
										BASE_URL +
										(isMobile
											? item?.Img_Mobile?.data?.attributes?.url
											: item?.Img_Desktop?.data?.attributes?.url)
									}
									alt={
										isMobile
											? item?.Img_Mobile?.data?.attributes?.name
											: item?.Img_Desktop?.data?.attributes?.name
									}
									layout="fill"
									objectFit="cover"
									objectPosition={"top"}
									priority
								/>
								<FadeIn setCanClick={setCanClick} slideNumber={slides} customClass={`banner-text-data`} pageLoading={pageLoading}>
									<span className="text-subtitle _eleX">{item?.Headline}</span>
									<h1 className="text-title _eleX">{item?.Title}</h1>
									<div className='text-description _eleX'>
									<p>{item?.Description}<br style={{ display: isMobile ? "none" : "" }} />{item?.Description2}</p>
                                        {/* <p>{item?.Description}</p>
                                        <p>{item?.Description2}</p> */}
                                    </div>
									<div
										className="text-button _eleX"
										onClick={() => {
											setNextRoute(t("navbar.developments"));
										}}
									>
										<Button
											text={item?.Button_Text}
											link={`/projects/${item?.Button_Slug}`}
										/>
									</div>
								</FadeIn>
							</div>
						</Fragment>
					))}
					{bannerData &&
						<div className={'swiper-navigation-continer'}>
							<div className="navigation-container">
								<div className="slide-number-container bold">
									<div>{slideNumber.toLocaleString('en-US', { minimumIntegerDigits: 2 })}</div>
									<div>{bannerData?.length.toLocaleString('en-US', { minimumIntegerDigits: 2 })}</div>
								</div>
								<progress id="file" value={slideNumber} max={bannerData?.length}></progress>
							</div>
							<div className="arrows-container">
								<Magnitizer dataDest={1.5}>
									<div onClick={() => canClick && prevSlides()} className={`swiper-button-prev swiper-button-prev-banner banner-arrow ${slides == 0 && "swiper-button-disabled"}`}></div>
								</Magnitizer>
								<Magnitizer dataDest={1.5}>
									<div onClick={() => canClick && nextSlides()} className={`swiper-button-next swiper-button-next-banner  banner-arrow ${slides == bannerData?.length - 1 && "swiper-button-disabled"}`}></div>
								</Magnitizer>
							</div>
						</div>
					}
				</div>
				{bannerData &&
					<FadeIn customClass={"search-home"}>
						{!isMobile && projectsTabs && (
							<SearchSection customClass="_eleY" projectsTabs={projectsTabs} />
						)}
					</FadeIn>
				}
				<ProgressTimer animationTrigger={animationTrigger} />
			</div>
		</>
	);
};

export default Banner;
