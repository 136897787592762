
import React, { useContext } from 'react'
import CountUp from "react-countup"
import { isMobileContext } from '../../contexts/isMobileContext'
import { Swiper, SwiperSlide } from 'swiper/react';
import { useRouter } from 'next/router';
import SingleNumber from './SingleNumber';
const Numbers = ({FactsAndFigures}) => {
    const { locale } = useRouter()
    const { isMobile } = useContext(isMobileContext)
    
    return (
        <div className='numbers-section'>
            {/* {isMobile ?
                <Swiper
                    key={"numbers"}
                    style={{ direction: locale == "ar" ? "rtl" : "ltr" }}
                    className='swiper-numbers-section'
                    slidesPerView={1.5}
                    spaceBetween={20}
                >
                    {FactsAndFigures?.map((number, index) => (
                        <SwiperSlide key={index}>
                            <SingleNumber index={index}
                            key={index}
                            sign={number.sign}
                            number={number.Figures}
                            text={number.Description}/>
                        </SwiperSlide>
                    ))}

                </Swiper>
                : */}
                {FactsAndFigures?.map((number, index) => (
                    <SingleNumber index={index}
                    key={index}
                    unit={number?.Unit}
                    number={number?.Figures}
                    text={number?.Description}/>
                ))
            }
        </div>
    )
}

export default Numbers