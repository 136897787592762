import Image from "next/legacy/image";
import { AnimatePresence, motion } from "framer-motion";
import Button from "../common/Button";
import { BASE_URL } from "../../utils/FetchData";
import { nextRouteContext } from "../../contexts/nextRouteContext";
import { useContext } from "react";
const OurPortfolioMobileCards = ({ item, index }) => {
    const { setNextRoute } = useContext(nextRouteContext)
    return (
        <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: .2, duration: .5 }}
            className="img-container">
            <div className="overlay"></div>
            <Image
                src={BASE_URL + item?.attributes?.Home_Card?.Img_Mobile?.data?.attributes?.url}
                alt={item?.attributes?.Home_Card?.Img_Mobile?.data?.attributes?.name}
                layout="fill"
                objectFit="cover"
            />
            <AnimatePresence>
                <motion.div className="slide-data"
                    key={index}
                    initial={{ opacity: 0 }}
                    exit={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: .5, delay: .2 }}
                >
                    <div className="logos">
                        <div className="main-logo">
                            <Image
                                src={
                                    item?.attributes?.Home_Card?.Logo?.data?.attributes?.url ? BASE_URL +
                                        item?.attributes?.Home_Card?.Logo?.data?.attributes?.url :
                                        "/icons/project-logo.svg"
                                }
                                alt={
                                    item?.attributes?.Home_Card?.Logo?.data?.attributes?.name ?
                                        item?.attributes?.Home_Card?.Logo?.data?.attributes?.name : "logo"
                                }
                                layout="fill"
                                objectFit="contain"
                                objectPosition={"top"}
                            />
                        </div>
                        {/* <div className="link-logo">
                            <Image
                                src={'/icons/360.svg'}
                                alt={'logo'}
                                layout="fill"
                                objectFit="contain"
                                objectPosition={"top"}
                            />
                        </div> */}
                    </div>
                    <div className="data">
                        <span className="bold">{item?.attributes?.Banner?.Headline}</span>
                        <h2 className="bold">{item?.attributes?.Project_Name}</h2>
                        <div className="buttons-group ">
                            <div>
                                <Button text={item?.attributes?.Home_Card?.Button_Text1}
                                link={`/projects/${item?.attributes?.Home_Card?.Button_Slug2}/booking`}
                                />
                            </div>
                            <div onClick={() => { setNextRoute(item?.attributes?.Project_Name) }}>
                                <Button text={item?.attributes?.Home_Card?.Button_Text2} link={`/projects/${item?.attributes?.Home_Card?.Button_Slug2}`} />
                            </div>
                        </div>
                    </div>
                </motion.div>
            </AnimatePresence>
        </motion.div>
    )
}

export default OurPortfolioMobileCards
